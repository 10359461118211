import React from 'react'
import { sanityImageUrl, prepareParagraph, titleToId } from '/src/utils/format'
import styles from './style.module.sass'

export default ({ discussion }) => {
  return (
    <div className={styles.discussion}>
      <div className={styles.anchor} id={titleToId(discussion.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{discussion.title}</h2>

      <div className={styles.content}>
        {discussion.content &&
          discussion.content.map(item => (
            <div className={styles.item} key={item._key}>
              {item._type === 'paragraph' && <p className={styles.paragraph}>{prepareParagraph(item.paragraph)}</p>}

              {item._type === 'iconTitle' && (
                <div className={styles.info}>
                  <img src={sanityImageUrl(item.icon)} className={styles.infoImage} alt={item.icon?.caption} />
                  <p className={styles.infoText}>{prepareParagraph(item.title)}</p>
                </div>
              )}

              {item._type === 'section' && (
                <div className={styles.section}>
                  {item.items &&
                    item.items.map(section => (
                      <div className={styles.sectionItem} key={section._key}>
                        {section._type === 'title' && (
                          <div className={styles.sectionTitle}>
                            <img
                              src={sanityImageUrl(section.icon)}
                              className={styles.sectionTitleImage}
                              alt={section.icon?.caption}
                            />
                            <p className={styles.sectionTitleText}>{prepareParagraph(section.title)}</p>
                          </div>
                        )}

                        {section._type === 'paragraph' && (
                          <p className={styles.sectionParagraph}>{prepareParagraph(section.paragraph)}</p>
                        )}

                        {section._type === 'list' && (
                          <ol className={styles.sectionList}>
                            {section.list &&
                              section.list.map((list, index) => <li key={index}>{prepareParagraph(list)}</li>)}
                          </ol>
                        )}
                      </div>
                    ))}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  )
}
