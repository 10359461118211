import React from 'react'
import { sanityImageUrl, prepareParagraph, titleToId } from '/src/utils/format'
import styles from './style.module.sass'

export default ({ background }) => {
  return (
    <div className={styles.background}>
      <div className={styles.anchor} id={titleToId(background.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{background.title}</h2>

      <div className={styles.top}>
        <img
          src={sanityImageUrl(background.descriptionIcon)}
          className={styles.topImage}
          alt={background.descriptionIcon?.caption}
        />
        <p className={styles.topText}>{background.description}</p>
      </div>

      <img src={sanityImageUrl(background.image)} className={styles.image} alt={background.image?.caption} />

      <div className={styles.content}>
        {background.content &&
          background.content.map(item => (
            <div className={styles.item} key={item._key}>
              {item._type === 'paragraph' && <p className={styles.paragraph}>{prepareParagraph(item.paragraph)}</p>}

              {item._type === 'section' && (
                <div className={styles.section}>
                  {item.items &&
                    item.items.map(section => (
                      <div className={styles.sectionItem} key={section._key}>
                        {section._type === 'title' && (
                          <div className={styles.sectionTitle}>
                            <img
                              src={sanityImageUrl(section.icon)}
                              className={styles.sectionTitleImage}
                              alt={section.icon?.caption}
                            />
                            <p className={styles.sectionTitleText}>{prepareParagraph(section.title)}</p>
                          </div>
                        )}

                        {section._type === 'paragraph' && (
                          <p className={styles.sectionParagraph}>{prepareParagraph(section.paragraph)}</p>
                        )}

                        {section._type === 'list' && (
                          <ol className={styles.sectionList}>
                            {section.list &&
                              section.list.map((list, index) => <li key={index}>{prepareParagraph(list)}</li>)}
                          </ol>
                        )}
                      </div>
                    ))}
                </div>
              )}
            </div>
          ))}
      </div>

      <div className={styles.bottom}>
        <img
          src={sanityImageUrl(background.purposeIcon)}
          className={styles.bottomImage}
          alt={background.purposeIcon?.caption}
        />
        <p className={styles.bottomText}>{background.purpose}</p>
      </div>
    </div>
  )
}
