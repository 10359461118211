import React from 'react'
import { prepareParagraph, sanityImageUrl } from '/src/utils/format'
import styles from './style.module.sass'

export default ({ info }) => {
  return (
    <div className={styles.info}>
      <div className={styles.top}>
        <div className={styles.text}>
          <p className={styles.titleSmall}>{info.smallTitle}</p>
          <h1 className={styles.title}>{info.title}</h1>
        </div>

        <div className={styles.image}>
          <img src={sanityImageUrl(info.image)} alt={info.image?.caption} />
        </div>
      </div>

      <p className={styles.paragraph}>{prepareParagraph(info.info)}</p>
    </div>
  )
}
