import React from 'react'
import { sanityImageUrl, prepareParagraph, titleToId } from '/src/utils/format'
import styles from './style.module.sass'

export default ({ references }) => {
  return (
    <div className={styles.references}>
      <div className={styles.anchor} id={titleToId(references.title)} data-js="scroll-section" />
      <h2 className={styles.title}>{references.title}</h2>

      <ol className={styles.content}>
        {references.content &&
          references.content.map((item, index) => (
            <li className={styles.item} key={index} id={`reference_${index + 1}`}>
              {prepareParagraph(item)}
            </li>
          ))}
      </ol>
    </div>
  )
}
